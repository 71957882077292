import { Navigate } from '@remix-run/react';

function MessageCampaignsIndexRoute() {
  return <Navigate to={'list'} replace />;
}

export const Component = MessageCampaignsIndexRoute;

export function clientLoader() {
  return null;
}
